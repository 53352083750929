import React from "react"

import "../styles/Home.styl"
import Header from "./Header"

export default function Home({ children }) {
  return (
    <div className="hero-wrapper">
      <Header />
      {children}
    </div>
  )
}
