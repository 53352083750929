import { useStaticQuery, graphql } from "gatsby"
import React, { useState, useEffect } from "react"
import { window, document } from "browser-monads"
import { useTranslation } from "react-i18next"
import Img from "gatsby-image"
import "../styles/SlideShow.styl"

function SliderShow() {
  const [index, setIndex] = useState(0)
  const [textSlider, setTextSlider] = useState("Valores")
  const [backgroundColor, setBackgroundColor] = useState("blue")
  const { t } = useTranslation()
  const { allFile } = useStaticQuery(
    graphql`
      query {
        allFile(
          sort: { fields: name, order: ASC }
          filter: { relativeDirectory: { eq: "slides" } }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(
                  maxWidth: 2400
                  quality: 100
                  traceSVG: { color: "rgb(80, 153, 230)", threshold: 85 }
                ) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    `
  )

  const length = allFile.edges.length - 1

  var arrows = document.getElementsByClassName("arrow")

  var handleMouseEnter = () => {
    for (let i = 0; i < arrows.length; i++) {
      arrows[i].classList.remove("--hidden")
    }
  }
  var handleMouseLeave = () => {
    for (let i = 0; i < arrows.length; i++) {
      arrows[i].classList.add("--hidden")
    }
  }

  const handleNext = () => {
    if (index === 4) {
      setIndex(0)
    } else {
      setIndex(index + 1)
    }
  }

  const handlePrevious = () => {
    if (index === 0) {
      setIndex(length)
    } else {
      setIndex(index - 1)
    }
  }

  useEffect(() => {
    if (index === 0) {
      setTextSlider(t("slider.client"))
      setBackgroundColor("#ff984d8c")
    }

    if (index === 1) {
      setTextSlider(t("slider.health"))
      setBackgroundColor("#adacad8c")
    }

    if (index === 2) {
      setTextSlider(t("slider.solutions"))
      setBackgroundColor("#1d431a8c")
    }

    if (index === 3) {
      setTextSlider(t("slider.flex"))
      setBackgroundColor("#ff984d8c")
    }

    if (index === 4) {
      setTextSlider(t("slider.together"))
      setBackgroundColor("#74b5d08c")
    }

    const timer = setInterval(() => {
      if (index === 4) {
        setIndex(0)
      } else {
        setIndex(index + 1)
      }
    }, 4000)

    return () => clearInterval(timer)
  }, [index])

  const { node } = allFile.edges[index]

  return (
    <>
      <div
        className="Slider"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="Slider__img">
          <Img
            fluid={node.childImageSharp.fluid}
            key={node.id}
            alt={node.name.replace(/-/g, " ").substring(2)}
            className="picture"
            style={{ height: "100%" }}
            imgStyle={{
              objectPosition: "center top",
              transition: "all 1s ease 0s",
            }}
          />
        </div>
        <div
          className="Slider__text"
          style={{ backgroundColor: backgroundColor }}
        >
          <p>{textSlider}</p>
        </div>
        <div className="Slider__navbar">
          <i className="arrow --hidden" onClick={handlePrevious}>
            ❬
          </i>
          <i className="arrow --hidden" onClick={handleNext}>
            ❭
          </i>
        </div>
      </div>
    </>
  )
}

export default SliderShow
