// import Image from "../components/image"
import React from "react"

import SEO from "../components/seo"
import Home from "../components/Home"
import Hero from "../components/Hero"

const IndexPage = () => (
  <>
    <SEO title="Home" lang="es" />
    <Home>
      <Hero />
    </Home>
  </>
)

export default IndexPage
