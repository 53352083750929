import React from "react"
import { useTranslation } from "react-i18next"

import SliderShow from "./SliderShow"
import "../styles/Hero.styl"

export default function Hero() {
  const { t } = useTranslation()
  return (
    <div className="hero">
      <div className="hero__text">
        <h1 className="hero__title">{t("hero.title")}</h1>
        <small className="hero__description">{t("hero.leaderCompany")}</small>
      </div>
      <div className="hero__image">
        <SliderShow />
      </div>
    </div>
  )
}
